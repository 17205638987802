import classnames from 'classnames/bind';

import styles from './Field.module.scss';

const cx = classnames.bind(styles);

function Field({ children, label, desc }) {
    return (
        <div className={cx('wrapper')}>
            <label className={cx('label')}>{label}</label>
            {children}
            <p className={cx('desc')}>{desc}</p>
        </div>
    );
}

export default Field;
