// import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

function LoadingSpinner({ caption }) {
    return (
        <div
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
                width: '100%',
                height: '100vh',
            }}
        >
            {/* <CircularProgress /> */}
            <span>{caption}</span>
        </div>
    );
}

LoadingSpinner.propTypes = {
    caption: PropTypes.string.isRequired,
};

export default LoadingSpinner;
