import { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import LoadingSpinner from '~/components/LoadingSpinner';
import authService from '~/services/authService';

function VerifyAccount() {
    const [searchParams] = useSearchParams();
    const { email, token } = Object.fromEntries([...searchParams]);
    const [verified, setVerified] = useState(false);

    useEffect(() => {
        if (email && token) {
            authService.verifyAccount({ email, token }).then((res) => setVerified(true));
        }
    }, [email, token]);

    if (!email || !token) {
        return <Navigate to="/not-found" />;
    }

    if (!verified) {
        return <LoadingSpinner caption="Verifying your account..." />;
    }

    // Cuối cùng nếu không gặp vấn đề gì + với verify thành công thì điều hướng về trang login cùng giá trị verifiedEmail
    return <Navigate to={`/login?verifiedEmail=${email}`} />;
}

export default VerifyAccount;
