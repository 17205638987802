import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import styles from './Control.module.scss';

const cx = classnames.bind(styles);

function Control({ value, name, type = 'text', placeholder, disabled, action, onChange, ...props }) {
    return (
        <>
            <div className={cx('wrapper')}>
                <input
                    value={value}
                    name={name}
                    type={type}
                    className={cx('control')}
                    placeholder={placeholder}
                    disabled={disabled}
                    onChange={onChange}
                    {...props}
                />
                {action}
            </div>
        </>
    );
}

Control.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
};

export default Control;
