import classnames from 'classnames/bind';
import ReactDOM from 'react-dom';
import { useEffect } from 'react';
import styles from './Modal.module.scss';

const cx = classnames.bind(styles);

function Modal({ children, size = '540px', open, title, onClose }) {
    const div = document.createElement('div');

    useEffect(() => {
        document.body.appendChild(div);

        return () => {
            document.body.removeChild(div);
        };
    }, [div]);

    return ReactDOM.createPortal(
        <>
            {open && (
                <div
                    className={cx('wrapper', {
                        closing: !open,
                    })}
                    onClick={onClose}
                >
                    <div className={cx('content')} style={{ width: size }} onClick={(e) => e.stopPropagation()}>
                        <button className={cx('close')} onClick={onClose}>
                            <span>×</span>
                        </button>
                        <div className={cx('body', {})}>
                            <h2 className={cx('heading')}>{title}</h2>
                            {children}
                        </div>
                    </div>
                </div>
            )}
        </>,
        div,
    );
}

export default Modal;
