import { useSelector } from 'react-redux';

import Group from '~/components/Group';
import Field from '~/components/Field';
import PhotoField from '~/components/PhotoField';
import Control from '~/components/Control';
import { useEffect, useState } from 'react';
import profileService from '~/services/profileService';
import Button from '~/components/Button';

function Personal() {
    const { userInfo } = useSelector((state) => state.user);
    const [isEdit, setIsEdit] = useState(false);
    const [value, setValue] = useState('');
    const [username, setUsername] = useState('');

    useEffect(() => {
        if (userInfo) {
            setValue(userInfo?.fullName);
            setUsername(userInfo?.username);
        }
    }, [userInfo]);

    const handleEdit = () => {
        setIsEdit(true);
    };

    const handleSave = () => {
        profileService.updateProfile({ fullName: value }).catch((err) => console.log(err));
        setIsEdit(false);
    };

    const handleCancel = () => {
        setIsEdit(false);
    };

    return (
        <Group title={'Info details'}>
            <Field
                label={'Display name'}
                desc={'Tên của bạn xuất hiện trên trang cá nhân và bên cạnh các bình luận của bạn.'}
            >
                <Control
                    value={value}
                    name={'fullName'}
                    placeholder={'Display name'}
                    onChange={(e) => setValue(e.target.value)}
                    disabled={!isEdit}
                    action={
                        <>
                            {!isEdit && (
                                <Button outline rounded onClick={handleEdit}>
                                    Edit
                                </Button>
                            )}

                            {isEdit && (
                                <div>
                                    <Button outline rounded onClick={handleSave}>
                                        Save
                                    </Button>
                                    <Button outline rounded onClick={handleCancel}>
                                        Cancel
                                    </Button>
                                </div>
                            )}
                        </>
                    }
                />
            </Field>
            <Field
                label={'Username'}
                desc={'Tên của bạn xuất hiện trên trang cá nhân: ' + window.location.origin + '/@' + userInfo?.username}
            >
                <Control
                    value={username}
                    name={'fullName'}
                    placeholder={'Display name'}
                    onChange={(e) => setUsername(e.target.value)}
                    disabled={!isEdit}
                    action={
                        <>
                            {!isEdit && (
                                <Button outline rounded onClick={handleEdit}>
                                    Edit
                                </Button>
                            )}

                            {isEdit && (
                                <div>
                                    <Button outline rounded onClick={handleSave}>
                                        Save
                                    </Button>
                                    <Button outline rounded onClick={handleCancel}>
                                        Cancel
                                    </Button>
                                </div>
                            )}
                        </>
                    }
                />
            </Field>
            <Field label={'Avatar'} desc={'Nên là ảnh vuông, chấp nhận các tệp: JPG, PNG hoặc GIF.'}>
                <PhotoField
                    name={'fullName'}
                    value={userInfo?.avatar}
                    placeholder={'Họ tên'}
                    desc={'Nên là ảnh vuông, chấp nhận các tệp: JPG, PNG hoặc GIF.'}
                />
            </Field>
        </Group>
    );
}

export default Personal;
