import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '~/components/Button';
import Field from '~/components/Field';
import Group from '~/components/Group';
import Image from '~/components/Image';
import Modal from '~/components/Modal';
import Control from '~/components/Control';
import authService from '~/services/authService';

function Security() {
    const { userInfo } = useSelector((state) => state.user);
    const [openModal, setOpenModal] = useState(false);
    const [qrCode, setQrCode] = useState('');
    const [otpToken, setOtpToken] = useState('');
    const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);

    // * Doi mat khau
    // * Chuc nang 2fa - Two factor authentication

    const handleOpenModal = () => {
        setOpenModal(true);
        authService.generate2FaQrCode().then((res) => setQrCode(res.data));
    };

    const handleSetup2fa = () => {
        ///
        authService.setup2fa({ otpToken }).then((res) => {
            console.log(res);
            setOpenModal(false);
            // set lai state user
        });
    };

    const handleDisable2fa = () => {
        if (window.confirm('disable 2fa?')) {
            authService.disable2fa().then((res) => {
                console.log(res);
                setOpenModal(false);
                // set lai state user
            });
        }
    };

    return (
        <Group title={'Security'}>
            <Field
                label={'Two factor authentication'}
                desc={'Tên của bạn xuất hiện trên trang cá nhân và bên cạnh các bình luận của bạn.'}
            >
                {!userInfo?.require2FA && !userInfo?.is2FAVerified ? (
                    <Button primary rounded onClick={handleOpenModal}>
                        Setup 2FA
                    </Button>
                ) : (
                    <Button primary rounded onClick={handleDisable2fa}>
                        Disable 2FA
                    </Button>
                )}
            </Field>
            <Field
                label={'Change password'}
                desc={'Tên của bạn xuất hiện trên trang cá nhân: ' + window.location.origin + '/@' + userInfo?.username}
            >
                <Button primary rounded onClick={() => setOpenChangePasswordModal(true)}>
                    Change Password
                </Button>
            </Field>

            {/* Two factor authentication Modal  */}
            <Modal open={openModal} title={'Two factor authentication (2FA)'} onClose={() => setOpenModal(false)}>
                {!qrCode ? <span>Loading...</span> : <Image src={qrCode} alt="qr-code" />}
                <Control
                    value={otpToken}
                    name={'otpToken'}
                    placeholder={'OTP Code'}
                    onChange={(e) => setOtpToken(e.target.value)}
                />
                <Button primary rounded onClick={handleSetup2fa}>
                    Setup 2FA
                </Button>
            </Modal>

            {/* Change Password Modal  */}
            <Modal
                open={openChangePasswordModal}
                title={'Change Password'}
                onClose={() => setOpenChangePasswordModal(false)}
            >
                <Field label={'Current password'}>
                    <Control type="password" name={'fullName'} placeholder={'Current password'} />
                </Field>
                <Field label={'New password'}>
                    <Control type="password" name={'newPassword'} placeholder={'New password'} />
                </Field>
                <Field label={'Repeat password'}>
                    <Control type="password" name={'repeatPassword'} placeholder={'Repeat password'} />
                </Field>
                <Button primary rounded>
                    Save changes
                </Button>
            </Modal>
        </Group>
    );
}

export default Security;
