import { Navigate } from 'react-router-dom';

function Home() {
    return (
        <div>
            <Navigate to={'/setting/personal'} />
            {/* <MentionExample /> */}
        </div>
    );
}

export default Home;
